import $ from './app/jquery-shim.js';
import Utils from './app/utils.js';
import Accordion from './app/accordion.js';
import Anchor from './app/anchor.js';
import FixedHeader from './app/fixedheader.js';
import HeightLine from './app/heightline.js';
import ResponsiveTable from './app/responsive-table.js';
import Slidebar from './app/slidebar.js';
import Tab from './app/tab.js';
import CurrentNav from './app/current-nav.js';
import OwlCarousel from 'owl.carousel';
import fontAwesome from "font-awesome/scss/font-awesome.scss"
import OwlCss from "owl.carousel/dist/assets/owl.carousel.css"
import OwlThemeCss from "owl.carousel/dist/assets/owl.theme.default.css"
import ScrollReveal from "scrollreveal"

function getRelativeUrl() {
  return window.location.origin
}

function getAssetUrl() {
  var baseUrl = getRelativeUrl()
  return baseUrl + "/wp-content/themes/lifeap-html/dist";
}

class App {
  constructor() {
    this.Utils = new Utils();
    this.Accordion = new Accordion();
    this.Anchor = new Anchor();
    this.FixedHeader = new FixedHeader();
    this.HeightLine = new HeightLine();
    this.ResponsiveTable = new ResponsiveTable();
    this.Slidebar = new Slidebar();
    this.CurrentNav = new CurrentNav();
    this.Tab = new Tab();


    $(function () {
      var owls = $('.owl-carousel');
      if (owls.length === 0) {
        return false
      }
      owls.imagesLoaded(function () {
        $('.c-case-slider .owl-carousel').owlCarousel({
          items: 4,
          margin: 16
        });
        $('.c-mainvisual__slider').owlCarousel({
          items: 1,
          nav: true,
          dots: false,
          loop: true,
          animateIn: 'fadeIn',
          animateOut: 'fadeOut',
          autoplayHoverPause: true,
          //autoplay: true,
          autoplaySpeed: 750,
          navText: ['<img src="' + getAssetUrl() + '/assets/images/icon-slider-prev.png" />', '<img src="' + getAssetUrl() + '/assets/images/icon-slider-next.png" />'],
        });
      });
    });
  }


}

window.GApp = new App();
(function ($) {

  var growApp = function () {
  };


  /*
  *
  * スマホメニューの高さを取得する
  *
  * */
  growApp.prototype.menuHight = function () {
    var win = $(window).innerWidth();
    if (win > 750) {
      return false;
    }

    var $smpHeaderHeight = $('.l-header').height();
    var windowHeight = window.innerHeight;
    var winH = windowHeight - $smpHeaderHeight;

    console.log($smpHeaderHeight);

    //動かすターゲットを取得
    var targetSlidebarWrap = $('.c-slidebar-menu'),
      targetSlidebarMenu = $('.c-slidebar__parent'),
      targetSlidebarBtn = $('.c-slidebar-menu__parent');


    //いざ実行(クリックしたら処理を実行する)
    targetSlidebarBtn.on('click', function () {
      $('.c-slidebar-menu').toggleClass('is-active');

    });

  }

  growApp.prototype.footerMenu = function () {
    var win = $(window).innerWidth();
    if (win > 950) {
      return false;
    }
    $(".c-footer-menu__title.is-parent").on("click", function (e) {
      e.preventDefault();
    });
    $('.c-footer-menu__block').on('click', function () {
      $(this).children(".c-footer-menu__title").toggleClass('is-open');
      $(this).children(".c-footer-menu__list.is-sub").slideToggle();
    })
  }

  // ボタンspan
  growApp.prototype.buttonWrap = function () {
    var button = $(".c-button");
    button.wrapInner("<span></span>");
  }
  //ボタンテキスト変更
  growApp.prototype.spMeueText = function () {
    var $btn = $(".c-slidebar-button");
    var $btnText = $(".c-slidebar-button__text");
    $btn.on("click", function () {
      var $text = $btnText.text();
      if ($text === "MENU") {
        $btnText.text("閉じる");
      } else {
        $btnText.text("MENU");
      }
    })
  }
  growApp.prototype.ScrollReveal = function () {
    window.sr = ScrollReveal({duration: 600, mobile: false});

    function domEach(items, callback) {
      if (typeof items === "string") {
        var items = $(items);
      }
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        // callback = callback.bind(item)
        callback(i, item);
      }
    }

    //top
    sr.reveal(".c-mainvisual__slider", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    sr.reveal(".c-heading-block.is-top", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    sr.reveal(".c-top-services__block", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    sr.reveal(".c-top-overview__image-block", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    sr.reveal(".c-top-news", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    //lower
    sr.reveal(".c-page-header", {scale: 1, duration: 1600, origin: 'right', distance: '20px',}, 100);
    sr.reveal(".c-page-header__inner", {delay: 400, scale: 1, duration: 1600, origin: 'left', distance: '20px',}, 100);
    sr.reveal(".c-heading-block.is-lower", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    //aboutus
    sr.reveal(".c-aboutus-top", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
    sr.reveal(".c-aboutus", {scale: 1, duration: 1600, origin: 'bottom', distance: '20px',}, 100);
  }


  $(function () {
    var app = new growApp();
    app.footerMenu();
    app.buttonWrap();
    app.spMeueText();
    app.ScrollReveal();
  });

})(jQuery);
